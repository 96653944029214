import model from './model';

export enum ElementId {
  Price = '#price',
  Currency = '#currency',
  Frequency = '#frequency',
  SetupFee = '#setupFee',
}

export interface PricingWidgetParams {
  price: string;
  currency: string;
  paymentFrequency: string;
  setupFee: string;
}

export default model.createController(({ $w }) => {
  return {
    pageReady: async () => {},
    exports: {
      setPricing: ({ price, currency, paymentFrequency, setupFee }: PricingWidgetParams) => {
        $w(ElementId.Price).text = price;
        $w(ElementId.Currency).text = currency;

        if (paymentFrequency) {
          $w(ElementId.Frequency).text = paymentFrequency;
        } else {
          $w(ElementId.Frequency).text = '';
          $w(ElementId.Frequency).collapse();
        }

        if (setupFee) {
          $w(ElementId.SetupFee).text = setupFee;
        } else {
          $w(ElementId.SetupFee).collapse();
          $w(ElementId.SetupFee).text = '';
        }
      },
    },
  };
});
